.video {
	width: 100%;
	max-width: 100%;
}

.videoContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}