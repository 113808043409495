.task {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100px;
  width: 100%;
  padding: 10px var(--page-padding);
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  margin: -1px 0;


  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 1.4rem;
    color: rgba(137, 137, 137, 1);
  }
}

.playIcon {color: var(--theme-primary)}