.content {
  display: flex;
  flex-flow: column;
  gap: 2rem;
  padding: 2rem 0;

  .title {
    font-size: 1.4rem;
    color: rgba(137, 137, 137, 1);
    margin-bottom: 1rem;
  }

  .date {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}

.actionIcon {
  width: 2.4rem;
  height: 2.4rem;
}