.breakdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 2.5rem;

  .statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;  
    

    .row {
      display: flex;
      align-items: center;
      gap: .5rem;
      font-weight: 'Roboto';

      .dashboardText {
        font-size: 1.4rem;
        font-weight: 500;
        color: white;
      }

      .normalText {
        font-size: 1.4rem;
        font-weight: 400;
        color: black;
      }
    }
  }
}

  
.circle {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 100%;
  border: 1px solid white;
}

.minor {
  background-color: var(--color-minor);
}

.major {
  background-color: var(--color-major);
}

.severe {
  background-color: var(--color-severe);
}

.crisis {
  background-color: var(--color-crisis);
}