.dropzone {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: 1px dashed var(--input-border-color);
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.default {
  display: none;
  color: transparent;

  & * {
    display: none;
  }
}

.listfiles{
  color: var(--theme-primary);
}

.icon{
  height: 30px;
  width: 30px;
}