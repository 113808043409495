.hotel {
  min-height: 100vh;
  overflow-y: hidden;
  padding: 16px;
}

.header {
  display: flex;
  padding: 10px;
  margin-top: 10px;
  gap: 10px;
  background-color: #DDDDDD;
  font-size: 14px;
}

.container {
  display: flex;
  padding: 5px;
  margin-top: 10px;
  gap: 10px;
  
  .img {
    padding: 10px;
    margin-right: 10px;
    height: 70px;
  }
  .image {
    width: 66px;
    height: 66px;
    border-radius: 66px;
    background-color: var(--font-color-light);
  }
}


.cuttext { 
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.overflow{ 
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
}

.controls {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 var(--page-padding);
  align-items: center;
  height: 50px;
}

.group {
  display: flex;
  align-items: center;
  height: 38px;
  background-color: #DDDDDD;
  padding-left: var(--page-padding);
  font-size: 14px;
}

.buttonlist{
  height: 3rem;
}

.iconContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.text {
  color: rgba(72, 72, 72, 1);
}