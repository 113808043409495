.item {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  padding: 0 var(--page-padding);
  height: 10rem;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: -1px;

  .title {
    font-size: 1.8rem;
  }

  .details {
    display: flex;
    align-items: center;
    gap: 2rem;

    .date {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.4rem;
    }
  }
}