.container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  background-color: #FFFFFF;
  height: 50px;
  border-radius: var(--input-border-radius);
  border: solid 1px var(--border-color-light);
  
  .search {
    outline: 0;
    height: inherit;
    color: #363636;
    font-size: inherit;
    border: 0;
    border-radius: 18px;
    padding: 0 10px;
    background-color: #FFFFFF;
  
    &::placeholder {
      font-size: inherit;
      color: lightgray;
    }
  }
  
  .icon {
    position: absolute;
    right: 26px;
    top: 50%;
    transform: rotateY(180deg) translateY(-50%);
    height: 18px;
  }
}

.transparent {
  background-color: transparent !important;
  border: 0 !important;
}