.location {
  margin-top: 3rem;
  
  .map {
    height: 200px;
    max-height: 200px;
    width: 100%;
    background-color: var(--foreground);
  }
}

.address {
  margin-top: 3rem;
  font-size: 18px;
  display: flex;
  flex-flow: column;
}

.contact {
  margin-top: 3rem;
  display: flex;
  flex-flow: column;



  .info {
    display: flex;
    align-items: center;

    .icon {
      padding: 10px;
      height: 40px;
    }
  }
}


.users {
  margin-top: 5rem;

  .title {
    font-size: 1.4rem;
    color: rgba(137, 137, 137, 1);
    margin-bottom: 1rem;
  }
  
  .list {
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
}

.controls {
  float: right;
  margin-top: .5rem;

}