.item {
    display: flex;
    height: 50px;

    .createNewButton{
        width: 100%;
        border: none;
        background-color: var(--theme-primary);
        color: #FFF;
        border-radius: 5px;
    }
}