.file {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 13px;

  .name {
    margin: 0;
    padding: 0
  }

  .size {
    color: var(--font-color-muted);
  }

  .info {
    color: var(--font-color-muted);
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  width: 40px;
  max-height: 40px;
}

.image {
  border-radius: 4px;
  border: 1px solid var(--border-color-dark);
}