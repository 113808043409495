@import './variables';

// Override bootstrap
.link {
  color: var(--font-color-link);

  &:visited {
    color: var(--font-color-link-visited);
  }
}

// --- Card ---
$card-spacer-y:                     12px;
$card-spacer-x:                     25px;
$card-border-width:                 1px;
$card-border-radius:                8px;
$card-border-color:               #edf2f9; 
$card-cap-bg:                     white;

.card-title,
.card-header {
  font-weight: 600;
  font-size: 14px;
  min-height: 60px;
}

.card-header {
  display: flex;
  align-items: center;
}

.card-body {
  font-size: 14px;
  padding: 14px 25px !important;
}


// --- Table ---
$table-cell-padding:          16px 16px 16px 25px;
$table-cell-padding-sm:       8px 8px 8px 25px;
$table-color:               #12263F;
$table-bg:                  #FFFFFF;
$table-border-width:          1px;
$table-border-color:        #F4F4F4;
$table-hover-bg:            #F9FBFD;
$table-th-font-weight:        500;
$table-accent-bg:           #f7fafc66;
$table-striped-order:         even;

.table {
  font-size: 13px;
  margin-bottom: 5px !important;

  thead {
    background-color:     #F9FBFD;
    color:                #95AAC9;
    text-transform:         uppercase;
    font-size:              10px;
    font-weight:            600;
    letter-spacing:         .5px;
    border-bottom:          0;

    tr {
      th {
        border-top:           0;
        border-bottom:        0;
      }
    }
  }
}


// --- Pagination ---
$pagination-padding-y-sm:           2px;
$pagination-padding-x-sm:           8px;
$pagination-color:                  var(--font-color);
$pagination-border-color:           var(--border-color-light);


// --- Badge ---
$badge-font-weight:                 500;
$badge-padding-y:                   calc(max(0.4em, 4px));
$badge-padding-x:                   .8em !important;


// Override defaults
hr {
  height: 1px;
  border: 0; 
  border-top: 1px solid var(--font-color-muted) !important;
}

a:hover {
  text-decoration: none !important;
}

input[type="search"] {
  font-family: var(--font-default);
  outline: 0;
  border: 0;
  width: 100%;
  height: 40px;
  color: var(--font-color);
  font-size: var(--font-size);

  &::placeholder {
    color: var(--font-color-muted);
    font-size: var(--font-size);
  }
}

ul {
  margin: 0 !important;
}

li {
  margin: 0!important;
}


// --- Layout ---
$grid-gutter-width: 0;

@for $i from 1 through 6 {
  .flex-gap-#{$i} {
    gap: #{$i * 10 + 'px'};
  }
}


// --- React Tooltip ---
.__react_component_tooltip * {
  color: white !important;
}