.page {
  display: flex;
  flex-flow: column;
  min-height: 100%;
  height: 100%;
  padding: 0 var(--page-padding);
}

.bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}