.item {
  display: flex;
  gap: 1.2rem;
}

.imageContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  margin-top: 20px;
  

  .label {
    position: absolute;
    left: 3.1rem;
    top: 5.3rem;
    transform: translate(-50%, -50%);
    width: 4rem;
    height: 4.5rem;
    font-family: 'Roboto Slab';
    font-size: 2rem;
    text-align: center;
  }
}

.content {
    margin-top: 2.4rem;

  .name {
    font-size: 1.4rem;
  }

  .total {
    font-size: 1.8rem;
  }
}
