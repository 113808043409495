.hotel {
  display: flex;
  padding: 16px var(--page-padding);
  gap: 18px;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;

  .icon {
    height: 6.6rem;
    width: 6.6rem;
    border-radius: 100%;
  }

  .details {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    font-family: var(--font-default);
    
    .name {
      font-size: 18px;
    }

    .city {
      font-size: 14px;
    }
  }
}