.header {
  display: flex;
  justify-content: center;
  gap: 5px;

  .hexagon {
    position: relative;
    width: 120px;
    transform: translateY(-30px);

    .image {
      width: 100%;
    }

    .statistic {
      display: flex;
      align-items: center;
      gap: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 1.6rem;
      
      .fraction {
        font-size: 3.5rem;
      }
    }
  }
}

.list {
  margin-bottom: 2rem;
  .title {
    margin: 0 var(--page-padding);
  }

  .taskName {
    font-size: 1.4rem;
  }
}