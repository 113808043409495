.item {
  position: relative;
  display: flex;
  border-top: 1px solid var(--button-border-color);
  
  .bar {
    margin: -1px 0;
    width: .6rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;

    .prefix {
      font-size: 1.1rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }
  }

  .snoozeIcon {
    position: absolute;
    top: var(--page-padding);
    right: var(--page-padding);
    width: 2rem;
  }
}


// Severities
.crisis {
  background-color: var(--color-crisis);
}

.severe {
  background-color: var(--color-severe);
}

.major {
  background-color: var(--color-major);
}

.minor {
  background-color: var(--color-minor);
}

.none {
  background-color: transparent;
}

.item:last-of-type {
  border-bottom: 1px solid var(--button-border-color);
}