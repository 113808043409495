// Input
.container {
  display: flex;
  flex-flow: column;
  gap: 5px;
  min-width: 200px;

  .label {
    color: var(--font-color);
    font-size: var(--input-font-size);
    font-weight: 400;
  }

  .error {
    font-size: 10px;
    font-weight: 400;
    margin-left: 5px;
    color: #ff746d;
  }

  .input {
    color: #363636;
    width: 100%;
    background-color: white;
    outline: 0;
    height: var(--input-height);
    border-radius: var(--input-border-radius);
    border: solid 1px var(--input-border-color);
    padding: 0 12px;
    font-size: 1.8rem;

    &:focus {
      border: solid 1px var(--focus-color);
    }

    &::placeholder {
      color: #d4d4d4;
    }
  }
}

.input:disabled {
  color: darkgray;
}

// Input Added

.inputWrapper {
  position: relative;
  width: 100%;
}

.hideButton {
  display: none;
}

.addButton {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}