.item {
  display: flex;
  align-items: center;
  gap: .9rem;
  
  .icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100%;
  }

  .text {
    font-size: 1.4rem;
    color: rgb(48, 48, 48);
  }
}