.card {
  height: 35rem;
  width: 100%;
  position: relative;
  border-radius: var(--dashboard-card-border-radius);
  overflow: hidden;

  .map {
    width: 100%;
    height: 100%;
  }

  .overlay {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 4rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
  }
}