.item {
  display: flex;
  gap: 3.5rem;
  padding: 2.5rem var(--page-padding);

  .icon {
    margin-top: .6rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1.4rem;
    }
  }
}