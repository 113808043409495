.container {
  width: 100%;
  border: 1px solid #DDDDDD;
  margin-bottom: -1px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 84px;
    padding: 0 var(--page-padding) 0 var(--page-padding);

    .text {
      font-size: 1.8rem;
      color: rgba(72, 72, 72, 1);
    }

    .icon {
      opacity: .5;

      &_open {
        transform: rotate(90deg);
      }
    }
  }

  .expandable {
    overflow: hidden;
    height: 0;
    width: 100%;
    transition: all .2s ease-in-out;

    &_open {
      height: auto;
    }
  }
}
