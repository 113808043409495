.item {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0 var(--page-padding);
}

.bordered {
  margin-bottom: -1px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}