h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 6px 0;
  color: black;
  font-weight: 400;
  font-family: var(--font-heading);
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 17px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 13px;
}

h6 {
  font-size: 10px;
}

p {
  color: var(--font-color);
  font-size: 15px;
  font-weight: 400;
}
