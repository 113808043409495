.AssessmentQuestion{
    display: flex;
}

.option{
    width: 60%;
}
.score{
    width: 20%;
}
.add{
    width: 20%;
}