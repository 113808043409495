.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  animation: fadeIn .2s ease-in;

  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 316px;
  height: 470px;

  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
}

.text {
  width: 90%;
  text-align: center;
}

