.chart {
  position: relative;
  width: 6.4rem;

  .middle {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 100%;
  }
}