.step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: var(--page-padding);
}

.score {
  font-family: 'Roboto Slab';
  font-size: 24px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .digit {
    font-size: 40px;
  }
}

.pass {
  color: var(--theme-primary);
}

.fail {
  color: var(--color-red);
}

.incomplete {
  color: var(--color-grey);
}