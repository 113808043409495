.circle {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 10rem;
}

.minor {
  background-color: var(--color-minor);
}

.major {
  background-color: var(--color-major);
}

.severe {
  background-color: var(--color-severe);
}

.crisis {
  background-color: var(--color-crisis);
}

.none {
  background-color: transparent;
}