.item {
  display: flex;
  flex-basis: 100%;
  height: 160px;
  border-top: 1px solid var(--foreground);
  border-bottom: 1px solid var(--foreground);
  margin: -1px 0;

  .bar {
    width: 6px;
    background-color: teal;
    margin: -1px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18px 22px;
    flex-basis: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        font-weight: 400;
        font-size: 1.8rem;
      }

      .status {
        text-transform: capitalize;

        .status_pass {
          color: var(--theme-primary);
        }

        .status_fail {
          color: var(--state-warning);
        }
        
        .status_incomplete {
          color: var(--state-incomplete);
        }

        .status_inprogress {
          color: var(--state-incomplete);
        }
      }
    }

    .hotel {
      display: flex;
      align-items: center;
      gap: 8px;

      .image {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: var(--font-color-light);
      }

      .title {
        font-size: 1.4rem;
        color: var(--font-color-muted);
      }
    }

    .progression {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4rem;
    }
  }
}

.healthy {
  color: var(--theme-primary) !important;
}

.warning {
  color: var(--state-warning) !important;
}

.incomplete {
  color: var(--state-incomplete) !important;
}