.item {
  display: flex;
  height: 132px;
  border-top: 1px solid var(--foreground);
  border-bottom: 1px solid var(--foreground);
  margin: -1px 0;

  .bar {
    width: 6px;
    margin: -1px 0;
  }

  .content {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 16px;

    .name {
      font-size: 1.8rem;
    }

    .date {
      font-size: 1.4rem;

      .calendar {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;

      .status {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }
}

.pass {
  & > .bar {
    background-color: var(--theme-primary);
  }
  & .status {
    color: var(--theme-primary);
  }
}

.fail {
  & > .bar {
    background-color: var(--state-warning);
  }
  & .status {
    color: var(--state-warning);
  }
}

.incomplete {
  & > .bar {
    background-color: var(--state-incomplete);
  }
}

.pending {
  & > .bar {
    background-color: rgba(0, 0, 0, 0);
  }
}