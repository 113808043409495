.cover {
  position: relative;
  height: 300px;
  border-radius: var(--dashboard-card-border-radius) var(--dashboard-card-border-radius) 0 0;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  .map {
    width: 100%;
    height: 100%;
  }

  .closeContainer {
    display: block;
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 100%;

    .close {
      padding: .2rem;
    }

    @media screen and (min-width: 641px) {
      display: none;
    }
  }
}

.alerts {
  padding: 1.2rem 0;
  background-color: #F1F7F8;
}


.resChangeFlex{
  .barchart{
    width: 100%;
  }
  
  @media screen and (min-width: 641px) {
    .barchart{
      width: 50%;
      float: left;
    }
  }

  &:after{
    clear: both; display:block; content:""; position:relative;
  }
}

.listingOnTab{
  flex-direction: column;

  .eachItemOnTab{
    width: 100%;
  }

  @media screen and (min-width: 641px) {
    flex-direction: row;
    flex-wrap: wrap;

    .eachItemOnTab{
      width: 45%;
    }
  }
}


