@import url("https://fonts.googleapis.com/css?family=Lato");
@import './theme/aius-theme';
@import '../node_modules/bootstrap/scss/bootstrap';
@import './theme/variables';
@import './theme/typography.scss';


html {
  height: 100%;
  touch-action: manipulation;
  font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
}

body {
  font-family: var(--font-default);
  font-size: 1.6rem;
  height: 100%;
  margin: 0;
  background-color: var(--bg-color);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

