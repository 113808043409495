.page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100%;
}

.content {
  padding: 16px 0;  
}

.description {
  font-size: 18px;
}


.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}