.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity .2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;

  &_visible {
    opacity: 1;
    visibility: visible !important;
  }

  .dialog {
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30rem;
    min-height: 20rem;
    
    transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(100px) scale(0.6);

    &_visible {
      transform: translateY(0) scale(1) !important;
    }

    .content {
      height: auto;
    }
   
    .controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
}
