.button {
  display: inline-flex;
  font-family: 'Roboto Slab';
  justify-content: center;
  align-items: center;
  min-width: 155px;
  
  padding: 13px 17px;
  border-radius: 30px;
  
  color: white;
  background-color: var(--theme-primary);
  cursor: pointer;
  transition: .1s all cubic-bezier(0.075, 0.42, 0.665, 1);
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.21px;

  &:hover {
    filter: brightness(120%);
  }
}

.large {
  height: 54px;
}

.normal {
  height: 48px;
}

.small {
  height: 36px;
  min-width: 55px;
  font-size: 12px;
  font-weight: 400;
}

.tiny {
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400;
  min-width: 0;
}

.wide {
  display: flex;
}

.light {
  background-color: var(--theme-light);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color-light);

  &:hover {
    filter: brightness(98%);
  }
}

.text {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  color: var(--button-text-color-light);
}

.disabled {
  filter: grayscale(30%) brightness(80%);
  opacity: .7;
  
  &:hover {
    filter: grayscale(30%) brightness(80%);
    opacity: .7;
  }
}

.light.disabled {
  filter: grayscale(0%) brightness(90%);
  
  &:hover {
    filter: grayscale(0%) brightness(90%);
  }
}

// Variants

.grey {
  background-color: #C8C8C8;
  color: #484848
}

.warning {
  background-color: var(--color-red);
}


.downloadButton {
  color: inherit !important;
  text-decoration: none !important;
}